
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function searchVehicleWidgetContainerRT () {
    return _createElement('div', { 'className': 'component-section-header component-section-header--hr' }, this.template === 'locked' ? _createElement('div', {
        'className': 'component-section-header--title h5 cmTemplate_locked',
        'key': '70'
    }, '\n    SEARCH RESULTS FOR ', this.vehicleString, '\n  ') : null, this.template !== 'locked' ? _createElement('div', {
        'className': 'component-section-header--title h5',
        'key': '237'
    }, 'SEARCH RESULTS') : null);
}
        export const componentNames = []