const baseFitmentFields = ['RideType', 'Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [];

const isProductPage = window.document.body.classList.contains('template-product');

globalThis.Convermax.categoryTitle = window.document
  .querySelector('meta[property="og:title"]')
  ?.attributes?.getNamedItem('content')?.textContent;

// https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
function luminance(r, g, b) {
  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;
  const GAMMA = 2.4;

  const a = [r, g, b].map((color) => {
    const processedColor = color / 255;
    return processedColor <= 0.03928
      ? processedColor / 12.92
      : Math.pow((processedColor + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const processedHex = hex.replace(shorthandRegex, function (_, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(processedHex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

window.Convermax.contrastToWhite = (hex) => {
  const lum1 = 1; // luminance(...[255, 255, 255])
  const lum2 = luminance(...hexToRgb(hex));
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export default {
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 12,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoRedirectDisabled: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
    },
    {
      name: 'SearchBox',
      location: { selector: '.header__search', class: 'cm_search-box-root__desktop' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: 'details-modal.header__search', class: 'header__icon cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFitmentFields,
      },
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'SearchVehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: 'body.template-page-search .component-section-header',
      },
      template: 'fitmentSearch/searchVehicleWidgetContainer',
    },
    {
      name: 'Garage',
      location: { selector: '#cm-garage', class: 'cm_garage__desktop cm_mobile-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { selector: '#cm-mobile-garage', class: 'cm_garage__mobile cm_desktop-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-verify-fitment',
        class: 'cm_vehicle-widget__verify-fitment-container',
      },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 1000,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
  ],
};
